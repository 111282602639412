/*
 *
 * PendingOrdersHistoryPageActions constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/PendingOrdersHistoryPage/DEFAULT_ACTION',
  FETCH_ORDERS_REQUEST = 'app/PendingOrdersHistoryPage/FETCH_ORDERS_REQUEST',
  FETCH_ORDERS_SUCCESS = 'app/PendingOrdersHistoryPage/FETCH_ORDERS_SUCCESS',
  FETCH_ORDERS_FAILURE = 'app/PendingOrdersHistoryPage/FETCH_ORDERS_FAILURE',
  FETCH_SUPPLIERS_FAILURE = 'app/PendingOrdersHistoryPage/FETCH_SUPPLIERS_FAILURE',
  SET_FILTER = 'app/PendingOrdersHistoryPage/SET_FILTER',
  APPLY_FILTER = 'app/PendingOrdersHistoryPage/APPLY_FILTER',
  CLEAR_FILTER = 'app/PendingOrdersHistoryPage/CLEAR_FILTER',
  SET_SEARCH = 'app/PendingOrdersHistoryPage/SET_SEARCH',
}

export default ActionTypes;
