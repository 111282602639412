import React, { Component } from 'react';
import styled from 'styled-components';
import { Collapse } from 'antd';
import { Viewport } from 'components/ViewportProvider';

const StyledCollapse = styled(Collapse)`
  &.ant-collapse {
    margin-bottom: 6pt;
    border: none;
    box-shadow: 4px 4px 8px rgba(4, 15, 15, 0.12);
  }
  & .ant-collapse-content {
    border-color: #dce0e9;
  }
  & .ant-collapse-content > .ant-collapse-content-box {
    padding: 8pt;
    padding-bottom: 2pt;
  }
  &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 12pt 8pt;
    margin-bottom: 0;
    background: white;
  }
  &.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    right: 24px;
    left: auto;
    color: rgba(0, 0, 0, 0.54);
  }
`;

class FilterWrapper extends Component<any> {
  public render() {
    return (
      <Viewport.Consumer>
        {({ width }) => {
          if (width >= 992) {
            return this.props.children;
          }

          return (
            <StyledCollapse>
              <Collapse
                items={[
                  {
                    key: '1',
                    label: 'Filter',
                    children: this.props.children,
                  },
                ]}
              />
            </StyledCollapse>
          );
        }}
      </Viewport.Consumer>
    );
  }
}

export default FilterWrapper;
