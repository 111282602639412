/**
 *
 * OrdersPage
 *
 */

import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import translations from 'translations';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Pagination } from 'antd';
import { createStructuredSelector } from 'reselect';
import { compose, Dispatch } from 'redux';

import OrderTable from 'components/OrderTable/OrderTable';
import Filter from './Filter';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import selectOrdersPage from './selectors';
import { applyFilter, clearFilter } from './actions';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';

import { RootState } from './types';

interface OwnProps {
  orderspage: any;
}

interface StateProps {}

interface DispatchProps {
  onApplyFilter: (data: any) => void;
  onClearFilter: () => void;
}

type Props = StateProps & DispatchProps & OwnProps;

export class UserOrderHistoryPage extends React.PureComponent<Props> {
  public onChangePage = (page: number) => {
    const { search } = this.props.orderspage;
    this.props.onApplyFilter({
      ...search,
      pagination: {
        page: page,
        size: 48,
      },
    });
  };
  public render() {
    const { orders, search } = this.props.orderspage;

    return (
      <Fragment>
        <Helmet>
          <title>{`${translations(messages.header)} | Nhà Cung Cấp Kamereo`}</title>
          <meta name="description" content={`${translations(messages.header)}`} />
        </Helmet>
        {/* <PageHeader>
          <FormattedMessage {...messages.pendingOrderHistory} />
        </PageHeader> */}
        <Filter
          statuses={search.filter.statuses}
          supplierName={search.filter.supplierName}
          orderDateStart={search.filter.orderDateRange && search.filter.orderDateRange.start}
          orderDateEnd={search.filter.orderDateRange && search.filter.orderDateRange.end}
          deliveryDateStart={search.filter.deliveryDateRange && search.filter.deliveryDateRange.start}
          deliveryDateEnd={search.filter.deliveryDateRange && search.filter.deliveryDateRange.end}
          onApplyFilter={this.props.onApplyFilter}
          onClearFilter={this.props.onClearFilter}
        />
        {orders.data.length ? (
          <Fragment>
            <OrderTable orders={orders.data} />
            {!!orders.data.length && (
              <Row>
                <Col span={24}>
                  <Pagination
                    onChange={this.onChangePage}
                    total={orders.totalResults}
                    pageSize={search.pagination.size}
                    current={search.pagination.page || 1}
                    defaultCurrent={search.pagination.page || 1}
                    showSizeChanger={false}
                  />
                </Col>
              </Row>
            )}
          </Fragment>
        ) : (
          <p style={{ padding: '40pt', textAlign: 'center' }}>
            <FormattedMessage {...messages.hasNoOrders} />
          </p>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  orderspage: selectOrdersPage(),
});

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): DispatchProps {
  return {
    onApplyFilter: (data: any) => dispatch(applyFilter(data)),
    onClearFilter: () => dispatch(clearFilter()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// <OwnProps> restricts access to the HOC's other props. This component must not do anything with reducer hoc
const withReducer = injectReducer<OwnProps>({
  key: 'pendingOrdersHistoryPage',
  reducer: reducer,
});
// <OwnProps> restricts access to the HOC's other props. This component must not do anything with saga hoc
const withSaga = injectSaga<OwnProps>({ key: 'pendingOrdersHistoryPage', saga: saga });

export default compose(withReducer, withSaga, withConnect)(UserOrderHistoryPage);
