import * as qs from 'utils/queryString';

import { call, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { getOrders } from 'utils/apollo';
import { orders as ordersActions } from './actions';
import { getStoreWithRetry } from 'containers/MainLayout/saga';
import { orderStatus as defaultStatus } from './Filter';
import ActionTypes from './constants';
import MainActionTypes from 'containers/MainLayout/constants';
import { push } from 'connected-react-router';
import selectOrdersPage from './selectors';
import { selectSuppliers, selectUser, makeSelectLocation } from 'containers/MainLayout/selectors';

function* initDataFlow() {
  const store = yield call(getStoreWithRetry);
  if (!store) {
    return;
  }

  const location = yield select(makeSelectLocation());
  const defaultSearch = {
    filter: {},
    pagination: {
      page: 0,
      size: 48,
    },
  };

  const parsedSearch = qs.parse(location.search);

  const search = location.search
    ? {
        filter: {
          ...defaultSearch.filter,
          ...parsedSearch.filter,
        },
        pagination: {
          ...defaultSearch.pagination,
          ...parsedSearch.pagination,
        },
      }
    : defaultSearch;

  const user = yield select(selectUser());

  const requestParam = computeSearchRequest(search, store.id, user.id);

  if (search.filter.supplierName !== '') {
    const { supplierHasOrders } = yield select(selectSuppliers());
    const supplier = supplierHasOrders.find((s: any) => s.name === search.filter.supplierName);
    if (supplier) {
      requestParam.filter.supplierId = supplier.id;
    }
  }

  yield put({
    type: ActionTypes.SET_FILTER,
    payload: search,
  });
  yield put(ordersActions.request(requestParam));

  const response = yield call(getOrders, requestParam);
  if (!response.errors) {
    yield put(ordersActions.success({ orders: response }));
  } else {
    yield put(ordersActions.failure(response.errors));
  }
}

function* ordersFlow() {
  yield takeLatest(MainActionTypes.SET_SELECTED_STORE, initDataFlow);
}

const isEmptyObject = obj => !obj || !Object.keys(obj).length;

function computeSearchRequest(search, storeId, userId) {
  const {
    filter: { supplierName, orderDateRange, deliveryDateRange, statuses, ...rest },
    pagination: { page, size } = { page: 0, size: 24 },
  } = search;

  const requestParam = {
    filter: {
      storeId: storeId,
      creatorId: userId,
      statuses: statuses && statuses.length ? statuses : defaultStatus,
      ...rest,
    },
    pagination: {
      page: page,
      size: size,
    },
  };

  if (!isEmptyObject(orderDateRange)) {
    requestParam.filter.orderDateRange = orderDateRange;
  }

  if (!isEmptyObject(deliveryDateRange)) {
    requestParam.filter.deliveryDateRange = deliveryDateRange;
  }

  return requestParam;
}

function* applyFilter() {
  while (true) {
    const { payload } = yield take(ActionTypes.APPLY_FILTER);
    const location = yield select(makeSelectLocation());
    const oldQueryString = location.search.length > 0 ? location.search.slice(1) : '';
    const newQueryString = qs.stringify(payload);

    if (oldQueryString === newQueryString) {
      continue;
    }

    yield put(
      push({
        search: newQueryString,
      }),
    );

    yield put({
      type: ActionTypes.SET_FILTER,
      payload: {
        ...payload,
        pagination: {
          page: 0,
          size: 48,
        },
      },
    });
  }
}

function* clearFilter() {
  while (true) {
    yield take(ActionTypes.CLEAR_FILTER);
    const store = yield select(selectOrdersPage());
    const location = yield select(makeSelectLocation());

    if (!location.search && isEmptyObject(store.search.filter)) {
      continue;
    }

    yield put(
      push({
        search: '',
      }),
    );

    yield put({
      type: ActionTypes.SET_FILTER,
      payload: {
        filter: {},
        pagination: {
          page: 0,
          size: 48,
        },
      },
    });
  }
}

// Individual exports for testing
export default function* userOrderHistoryPage() {
  // See example in containers/HomePage/saga.js
  yield fork(initDataFlow);
  yield fork(ordersFlow);
  yield fork(applyFilter);
  yield fork(clearFilter);
}
