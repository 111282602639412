import Button from 'components/Button/Button';
import Calendar from 'components/TrackingComponents/Calendar/Calendar';
import FilterWrapper from '../OrdersPage/FilterWrapper';
import { FormattedMessage } from 'react-intl';
import { Radio } from 'antd';
import React from 'react';
import Select from 'components/Select/Select';
import { getStatusColor } from 'components/OrderTable/StatusIcon';
import media from 'utils/mediaStyle';
import messages from './messages';
import styled from 'styled-components';
import dayjs from 'dayjs';

const Label = styled.label`
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  margin-bottom: 5px;
`;

const FilterItem = styled.div`
  width: 100%;
  > div {
    width: 100%;
  }
  margin-bottom: 6pt;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const FilterContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  ${media.md`
    flex-direction: row;
    ${FilterItem} + ${FilterItem} {
      padding-left: 20px;
    }
  `};
`;

const FilterActions = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 6pt;
  ${Button} {
    min-width: 100pt;
  }
  ${Button} + ${Button} {
    margin-left: 10pt;
  }
  ${media.md`
    ${Button} {
      width: auto;
    }
    flex-direction: row;
    ${Button} + ${Button} {
      margin-left: 20px;
    }
  `};
`;

const StyledSelect = styled(Select)`
  & .ant-select-selection {
    background-color: #ffffff;
    border-color: #d9d9d9;
    padding: 7px 0;
  }
  & .ant-select-selection--multiple .ant-select-selection__choice {
    padding: 0;
  }
  & .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    border-radius: 12px;
  }
  & .anticon.ant-select-remove-icon {
    color: white;
  }
`;

export const orderStatus = ['PENDING_APPROVAL', 'APPROVAL_DISAPPROVED'];

const orderStatusFilter = [
  ...orderStatus.map((status) => ({
    value: status,
    label: <FormattedMessage {...messages[status]} />,
    color: `${getStatusColor(status)}`,
  })),
];

export default class Filter extends React.Component<any> {
  public state = {
    statuses: this.props.statuses,
    orderDateStart: this.props.orderDateStart && dayjs(this.props.orderDateStart),
    orderDateEnd: this.props.orderDateEnd && dayjs(this.props.orderDateEnd),
    deliveryDateStart: this.props.deliveryDateStart && dayjs(this.props.deliveryDateStart),
    deliveryDateEnd: this.props.deliveryDateEnd && dayjs(this.props.deliveryDateEnd),
    selectedDate: this.props.deliveryDateStart ? 'deliveryDate' : 'orderDate',
  };

  public componentWillReceiveProps(nextProps: any) {
    this.setState({
      statuses: nextProps.statuses,
      orderDateStart: nextProps.orderDateStart && dayjs(nextProps.orderDateStart),
      orderDateEnd: nextProps.orderDateEnd && dayjs(nextProps.orderDateEnd),
      deliveryDateStart: nextProps.deliveryDateStart && dayjs(nextProps.deliveryDateStart),
      deliveryDateEnd: nextProps.deliveryDateEnd && dayjs(nextProps.deliveryDateEnd),
      selectedDate: nextProps.deliveryDateStart ? 'deliveryDate' : 'orderDate',
    });
  }

  public computeFilter = () => {
    const { statuses, selectedDate, orderDateStart, orderDateEnd, deliveryDateStart, deliveryDateEnd } = this.state;

    const dateRange =
      selectedDate === 'orderDate'
        ? orderDateStart &&
          orderDateEnd && {
            orderDateRange: {
              start: orderDateStart.startOf('day').toDate(),
              end: orderDateEnd.endOf('day').toDate(),
            },
          }
        : deliveryDateStart &&
          deliveryDateEnd && {
            deliveryDateRange: {
              start: deliveryDateStart.startOf('day').toDate(),
              end: deliveryDateEnd.endOf('day').toDate(),
            },
          };

    return {
      statuses: statuses,
      ...(dateRange || {}),
    };
  };

  public onApplyFilter = () => {
    this.props.onApplyFilter({
      filter: this.computeFilter(),
    });
  };

  public onClearFilter = () => {
    this.setState({
      statuses: undefined,
    });

    this.props.onClearFilter();
  };

  public onChangeFilter = (key) => (value) => {
    this.setState({
      [key]: value,
    });
  };

  public onChangeDeliveryDate = ([start, end]) => {
    this.setState({
      deliveryDateStart: start,
      deliveryDateEnd: end,
    });
  };

  public onChangeOrderDate = ([start, end]) => {
    this.setState({
      orderDateStart: start,
      orderDateEnd: end,
    });
  };

  public toggleSelectedDate = (event) => {
    this.setState({
      selectedDate: event.target.value,
    });
  };

  public render() {
    const { statuses, selectedDate, orderDateStart, orderDateEnd, deliveryDateStart, deliveryDateEnd } = this.state;

    return (
      <FilterWrapper>
        <FilterContainer>
          <FilterItem>
            <Label>
              <Radio.Group name="dateSelect" value={selectedDate} onChange={this.toggleSelectedDate}>
                <Radio value="orderDate">
                  <FormattedMessage {...messages.orderDate} />
                </Radio>
                <Radio value="deliveryDate">
                  <FormattedMessage {...messages.deliveryDate} />
                </Radio>
              </Radio.Group>
            </Label>
            {selectedDate === 'orderDate' ? (
              <Calendar
                trackingCategory="User Order History Page | DatePicker"
                trackingAction="Search Order Date"
                value={[orderDateStart, orderDateEnd]}
                range
                onChange={this.onChangeOrderDate}
                size="large"
              />
            ) : (
              <Calendar
                trackingCategory="User Order History Page | DatePicker"
                trackingAction="Search Delivery Date"
                value={[deliveryDateStart, deliveryDateEnd]}
                range
                onChange={this.onChangeDeliveryDate}
                size="large"
              />
            )}
          </FilterItem>
          <FilterItem>
            <StyledSelect
              size="large"
              mode="multiple"
              allowClear={true}
              placeholder={<FormattedMessage {...messages.selectStatus} />}
              value={statuses}
              type="secondary-2"
              items={orderStatusFilter}
              onChange={this.onChangeFilter('statuses')}
            />
          </FilterItem>
        </FilterContainer>
        <FilterActions>
          <div style={{ flex: 1 }} />
          <Button onClick={this.onClearFilter}>
            <FormattedMessage {...messages.clear} />
          </Button>
          <Button type="primary" onClick={this.onApplyFilter}>
            <FormattedMessage {...messages.apply} />
          </Button>
        </FilterActions>
      </FilterWrapper>
    );
  }
}
